/* START entire app container */
.app-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid gray;
  overflow: hidden;
}
/* END entire app container */

/* START main section */
.main-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  height: 70%;
  background-color: #222563;
  background-size: 100% 100%, 100% 100%, 100% 100%;
}
.main-section.forest {
  background-image: url(./assets/ground.png), url(./assets/forest.png), url(./assets/aurora.png);
}
.main-section.mountains {
  background-image: url(./assets/ground.png), url(./assets/mountains.png), url(./assets/aurora.png);
}
.test-point {
  position: absolute;
  bottom: 33.5%;
  left: 50%;
  height: 14%;
  width: 1.24%;
  transform: translateX(-50%);
  overflow: visible;
  background-image: url(./assets/testpoint.png);
  background-size: 100% 100%;
}
.test-point > p {
  position: absolute;
  top: 0;
  left: 150%;
  color: #ffffff;
  white-space: nowrap;
  line-height: 1;
  font-size: 0.875rem;
}
.pipe {
  position: absolute;
  width: 90%;
  height: 9%;
  top: 83.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  background-image: url(./assets/pipe.png);
  background-size: 100% 100%;
}
.pipe > p {
  position: absolute;
  top: 110%;
  left: 3%;
  white-space: nowrap;
  color: white;
  font-size: 0.875rem;
}
.mag-obs {
  position: absolute;
  height: 24%;
  width: 14%;
  bottom: 33.5%;
  left: 35%;
  transform: translateX(-50%);
  background-image: url(./assets/magobs.png);
  background-size: 100% 100%;
}
.mag-obs > p {
  position: absolute;
  left: 5%;
  top: 25%;
  width: 90%;
  color: black;
}
.axes-container {
  position: absolute;
  top: -65px;
  left: 10px;
  width: 300px;
  height: 300px;
  /* background-color: #d3d3d381; */
}
.axis-label {
  font-size: 14px;
  white-space: nowrap;
  margin-top: 40px;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #ffffffc5;
}
.axis-label.top {
  margin-top: 0;
  margin-bottom: 50px;
}
.axis-label.left {
  margin-right: 65px;
}
/* END main section */

/* START settings section */
.settings-section {
  position: absolute;
  top: 0;
  left: 75%;
  width: 25%;
  height: 70%;
  border-left: 1px solid gray;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
}
.settings-section .body-container {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.settings-section.playing::after {
  content: "Please Pause the Simulation to Change Settings";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #666666;
  opacity: 0.85;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
/* END settings section */

/* START setting control */
.settings-control {
  margin-bottom: 20px;
}
.settings-input {
  margin-top: 5px;
}
/* END setting control */

/* START bottom section */
.bottom-section {
  position: absolute;
  display: flex;
  top: 70%;
  left: 0;
  width: 100%;
  height: 30%;
  border-top: 1px solid gray;
}
.bottom-item {
  position: relative;
  width: 25%;
  height: 100%;
  padding: 0;
  border-left: 1px solid gray;
  display: flex;
  flex-direction: column;
}
.header-container {
  display: flex;
  background-color: white;
  margin: 0;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}
.body-container {
  flex-grow: 1;
  padding: 10px;
}
.scope-field-button {
  margin-right: 6px;
  padding: 2px 5px;
  color: white;
  background-color: #697386;
  border: none;
  border-radius: 2px;
}
.scope-field-button.active {
  background-color: red;
}
.mag-graph .field-x {
  margin-left: auto;
}
.mag-graph .field-f {
  margin-right: 20px;
}
.mag-graph .body-container {
  padding: 0;
}
.p2s-graph .body-container {
  padding: 0;
}
.time-location {
  background-color: lightgray;
}
.time-location .body-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.time-location .body-container p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.play-controls {
  background-color: lightgray;
  user-select: none;
}
.play-controls > .body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.play-controls .playback-controls {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-inline: 10px;
}
.play-controls .playback-slider {
  display: flex;
  justify-content: center;
  width: 80%;
  margin-top: 0px;
}
.play-controls .playback-slider .slider {
  width: 100%;
  margin-top: 40px;
}
.reset {
  background: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.reset > span {
  margin-left: 5px;
}
canvas {
  position: absolute;
}
/* END bottom section */

/* START slider */
.slider {
  position: relative;
  padding-top: 12px;
  padding-bottom: 24px;
}
.slider input {
  width: 100%;
}
.slider .current-value {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: #464646;
  color: white;
  padding: 0px 5px 2px;
  font-size: small;
  border-radius: 3px;
}
.slider .current-value.disabled {
  background-color: #8f8f8f;
}
.slider .current-value.bottom {
  top: 60px;
}
.slider .min-value {
  position: absolute;
  left: 0;
  top: 33px;
}
.slider .max-value {
  position: absolute;
  right: 0;
  top: 33px;
}
.slider .display-value {
  position: absolute;
  font-size: 0.8em;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* END slider */

/* START input range custom style */
input[type="range"] {
  appearance: none;
  background: repeating-linear-gradient(90deg, #ccc 0, #ccc 2px, transparent 0, transparent 1.25em)
    calc(0.5 * (1.5em - 2px)) 1.875em / calc(12.5em + 2px) 0.75em no-repeat;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 4px;
  background: #5469d4;
  border: none;
  border-radius: 3px;
}

input[type="range"][disabled]::-webkit-slider-runnable-track {
  background: #8f8f8f;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #c1c9d2;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  margin-top: -6px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #5469d4;
}
/* END input range custom style */

/* START scroll bar */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #5e5e5e;
  border-radius: 5px;
  /* outline: 1px solid slategrey; */
}
/* END scroll bar */

/* STRAT play control  button */
.pause {
  transform: scale(1.15);
}
.play {
  transform: scaleX(1.1);
}
.fast-forward {
  transform: scaleY(1.3);
}
.active {
  fill: #2c313b;
}
/* END play control button */

/* START button disabled */
.svg-button.disabled {
  cursor: not-allowed;
  fill: #afafaf;
}
button.disabled * {
  cursor: not-allowed;
  color: #b9b9b9;
}
/* END disabled */
.disclaimer-modal-container {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 45, 99, 0.78);
  justify-content: center;
  align-items: center;
}
.disclaimer-modal-container.open {
  display: flex;
}
.disclaimer-modal {
  max-width: 760px;
}
.disclaimer-modal p {
  color: #697386;
  padding: 10px;
}
.disclaimer-modal > .modal-header {
  background-color: white;
}
.disclaimer-modal > .modal-body {
  background-color: #d9d9d9;
}
.disclaimer-modal > .modal-footer {
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  padding-block: 20px;
}
.disclaimer-button {
  background: #007aff;
  border-radius: 10px;
  color: white;
  border: none;
  padding: 10px 30px;
}

/* Loading */
.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #282828;
  color: white;
  overflow: hidden;
  font-size: 48px;
}
